// 测试域名

// const testDomin = 'https://agbobapi.kikicoco.com.cn'
 const testDomin = 'https://agbobapi.yipinghaojiu.net'

// https://agbobapi.kikicoco.com.cn    测试
// https://agbobapi.yipinghaojiu.net   生产

// 正式域名
// const formalDomain = 'https://agbobapi.kikicoco.com.cn'
 const formalDomain = 'https://agbobapi.yipinghaojiu.net'


// 环境变量
const env = process.env.NODE_ENV
const origin = env === 'development' ? testDomin : formalDomain

// 项目配置
const config = {
  // 域名
  domain: origin
}

export { config }
