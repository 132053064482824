/**
 * @module axios配置
 */
import axios from 'axios'
import { config } from './config'
import { message } from 'antd';

// 权限前缀
let myAxios = axios.create({})

// 超时时间设置
myAxios.defaults.timeout = 10000

// axios请求拦截
myAxios.interceptors.request.use(conf => {
  // 设置 token
  if (sessionStorage.getItem('auth')) {
    conf.headers['token'] = sessionStorage.getItem('auth')
  }
  // api 处理
  conf.url = `${config.domain}${conf.url}`;
  return conf;
})

// axios返回拦截
myAxios.interceptors.response.use(
  response => {
    return Promise.resolve(response.data)
  },
  error => {
    if(error.response.status === 401){
      message.error('登录过期，请重新登录');
      window.location.href = '/login';
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export default myAxios
