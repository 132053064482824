import React from 'react';
import ReactDOM from 'react-dom';
import App from './page/App';
import myAxios from "./plugins/axios"
import myAxios1 from "./plugins/axios1"
import Helper from './plugins/tool'
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

React.Component.prototype.$http = myAxios;
React.Component.prototype.$http1 = myAxios1;
React.Component.prototype.$tool = Helper;
//全局挂载
ReactDOM.render(<App />, document.getElementById('root'));
