import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { mainRouter } from "../routers";
import './App.sass'

class App extends Component{
    constructor(props){
        super(props)
        this.state = {
        }
    }
    render(){
        return(
            <Router>
                <Switch>
                {
                    mainRouter.map((item, index) => {
                        return <Route key={index} exact={item.exact} path={item.path} component={item.component} />
                    })
                }
                </Switch>
            </Router>
        )
    }
}
export default App;
