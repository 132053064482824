const Helper = {};

// 时间格式化 年-月-日 时:分:秒
Helper.getFormatDates= function(dates){
    if(dates === '' || dates === null){
        return '--';
    }
    var date = new Date(dates);
    var year = date.getFullYear();
    var month = date.getMonth()+1;
    var data = date.getDate();

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    return `${year}-${month < 10 ? '0'+month:month}-${data < 10 ? '0'+data:data} ${hours < 10 ? '0'+hours:hours}:${minutes < 10 ? '0'+minutes:minutes}:${seconds < 10 ? '0'+seconds:seconds}`;
}

// 时间格式化 年-月-日
Helper.getFormatDate= function(dates){
    if(dates === '' || dates === null){
        return '--';
    }
    var date = new Date(dates);
    var year = date.getFullYear();
    var month = date.getMonth()+1;
    var data = date.getDate();
    return `${year}-${month < 10 ? '0'+month:month}-${data < 10 ? '0'+data:data}`;
}

// 两个浮点数求和
Helper.accAdd = (num1,num2) => {
    var r1,r2,m;
    try{
        r1 = num1.toString().split('.')[1].length;
    }catch(e){
        r1 = 0;
    }
    try{
        r2=num2.toString().split(".")[1].length;
    }catch(e){
        r2=0;
    }
    m=Math.pow(10,Math.max(r1,r2));
    // return (num1*m+num2*m)/m;
    return Math.round(num1*m+num2*m)/m;
} 

// 两个浮点数相减
Helper.accSub = (num1,num2) => {
    var r1,r2,m;
    try{
        r1 = num1.toString().split('.')[1].length;
    }catch(e){
        r1 = 0;
    }
    try{
        r2=num2.toString().split(".")[1].length;
    }catch(e){
        r2=0;
    }
    m=Math.pow(10,Math.max(r1,r2));
    // return (num1*m+num2*m)/m;
    return Math.round(num1*m+num2*m)/m;
}

// 两数相除
Helper.accDiv = (num1,num2) => {
    var t1,t2,r1,r2;
    try{
        t1 = num1.toString().split('.')[1].length;
    }catch(e){
        t1 = 0;
    }
    try{
        t2=num2.toString().split(".")[1].length;
    }catch(e){
        t2=0;
    }
    r1=Number(num1.toString().replace(".",""));
    r2=Number(num2.toString().replace(".",""));
    return (r1/r2)*Math.pow(10,t2-t1);
 }

// 文件流导出
Helper.fileStream = function(res){
    const reader = new FileReader();
    reader.readAsDataURL(res); // 转换为base64，可以直接放入a标签href
    reader.onload = e => {
        // 转换完成，创建一个a标签用于下载
        let a = document.createElement("a");
        a.style.display = "none";
        a.download = "data.xls";

        a.href = e.target.result;
        document.body.appendChild(a); // 修复firefox中无法触发click
        a.click();
        document.body.removeChild(a);
    };
}

// 文件流导出
Helper.fileStreamWord = function(res){
    const reader = new FileReader();
    reader.readAsDataURL(res); // 转换为base64，可以直接放入a标签href
    reader.onload = e => {
        // 转换完成，创建一个a标签用于下载
        let a = document.createElement("a");
        a.style.display = "none";
        a.download = "data.doc";

        a.href = e.target.result;
        document.body.appendChild(a); // 修复firefox中无法触发click
        a.click();
        document.body.removeChild(a);
    };
}

// 返桶类型
Helper.returnBucketType= function(res){
    switch(res){
        case 1:
            return "待订单审核";
        case -2:
            return "已作废";
        case 2:
            return "待付款";
        case 3:
            return "待财务审核";
        case 4:
            return "待发货确认";
        case 5:
            return "待入库";
        case 6:
            return "已完成";
        case -1:
            return "已作废";
        case -4:
            return "已作废";
        default:
            return '';
    }
}

// 订货单类型
Helper.orderType= function(res){
    switch(res){
        case -61:
            return "提货卡撤回";
        case -60:
            return "订货单撤回";
        case -50:
            return "订货单作废";
        case -40:
            return "退款驳回订货单";
        case -31:
            return "代理商取消订货单";
        case -32:
            return "财务上传付款凭证完成";
        case -30:
            return "退款财务审核通过";
        case -25:
            return "退款主管审核通过";
        case -24:
            return "退款主管审核驳回";
        case -20:
            return "退款运营审核通过";
        case -19:
            return "退款运营审核驳回";
        case -11:
            return "未支付待审核";
        case -10:
            return "退款单生成";
        case 20:
            return "待确认审核";
        case 30:
            return "代付款审核";
        case 40:
            return "代理商确认付款";
        case 50:
            return "财务确认已付款";
        case 60:
            return "分仓";
        case 70:
            return "生成采购单";
        case 80:
            return "发货完成";
        case 90:
            return "收货完成";
        case 100:
            return "更新订货单备注";
        case 110:
            return "更新订货单物流单号";
        case 120:
            return "申请售后";
        case 121:
            return "售后运营审核通过";
        case 122:
            return "售后运营审核驳回";
        case 123:
            return "售后主管审核通过";
        case 124:
            return "售后主管审核驳回";
        case 125:
            return "售后财务审核通过";
        case 126:
            return "售后单已发货";
        case 127:
            return "售后鉴定合格";
        case 128:
            return "售后鉴定不合格";
        default:
            return '';
    }
}

// 操作类型
Helper.workType= function(res){
    switch(res){
        case -61:
            return '提货卡退回';
        case -60:
            return '订货单撤回';
        case -50:
            return '订货单作废';
        case -40:
            return '退款驳回订货单';
        case -30:
            return '退款财务审核通过';
        case -20:
            return '退款运营审核通过';
        case -11:
            return '未支付作废';
        case -10:
            return '退款单生成';
        case 20:
            return '待确认审核';
        case 30:
            return '代付款审核';
        case 40:
            return '代理商确认付款';
        case 50:
            return '财务确认已付款'; 
        case 60:
            return '分仓';
        case 70:
            return '生成采购单';
        case 80:
            return '发货完成';
        case 90:
            return '收货完成';
        case 100:
            return '修改备注';
        default:
            return '无类型';
    }
}

// 审核状态
Helper.readStatus= function(res){
    switch(res){
        case "SUB":
            return '待审核';
        case "SUC":
            return '审核通过';
        case "REJECT":
            return '审核驳回';
        default:
            return '无类型';
    }
}

// 退款状态
Helper.returnMony = function(res){
    switch(res){
        case -31:
            return '退款完成';
        case -3:
            return '待上传付款凭证';
        case -21:
            return '待财务审核';
        case -2:
            return '待主管确认';
        case -1:
            return '待运营确认';    
        default:
            return '无类型';
    }
}

// 退款状态
Helper.returnMonys = function(res){
    switch(res){
        case -31:
            return '上传付款凭证成功，退款完成';
        case -3:
            return '财务确认退款，待上传付款凭证';
        case -21:
            return '主管审核通过，待财务审核';
        case -2:
            return '运营审核通过，待主管确认';
        case -1:
            return '待确认退款订单，待运营确认';
        default:
            return '无类型';
    }
}

// 提货卡
Helper.pickUpCard = res => {
    switch(res){
        case 1:
            return '不定额卡';
        case 2:
            return '定额卡';
        case 3:
            return '专用卡';
        case 4:
            return '通用卡';
        default:
            return '无状态'
    }
}

// 售后
Helper.afterSale = res => {
    switch(res){
        case -10:
            return '运营审核拒绝';
        case -11:
            return '运营主管审核拒绝';
        case -20:
            return '财务审核拒绝';
        case 10:
            return '运营审核中';
        case 15:
            return '运营主管审核';
        case 16:
            return '代理商待付款';
        case 20:
            return '财务审核';
        case 25:
            return '待发货';
        case 30:
            return '厂家鉴定';
        case 40:
            return '产品不合格';
        case 50:
            return '产品合格';
        default:
            return '无状态  '
    }
}

// 订货但状态
Helper.orderBuyType = res => {
    switch(res){
        case -5:
            return '作废';
        case -32:
            return '代理商取消订货单';
        case -31:
            return '上传付款凭证成功，退款完成';
        case -3:
            return '财务确认退款，待上传付款凭证';
        case -21:
            return '主管审核通过，待财务审核';
        case -2:
            return '运营审核通过，待主管确认';
        case -1:
            return '待确认退款订单，待运营确认';
        case 1:
            return '待确认';
        case 2:
            return '待付款';
        case 21:    
            return '代理商确认付款';
        case 3:
            return '财务确认已付款';   
        case 4:
            return '已分仓，待生成采购单';
        case 5:
            return '采购单完成，待发货';
        case 61:
            return '部分发货';
        case 6:
            return '已发货，待收货';
        case 7:
            return '已收货，待评价';
        case 8:
            return '已经评价';
        default:
            return '无状态'
    }
}

// 门店类型
Helper.storeType = res => {
    switch(res){
        case 'direct':
            return '直营店';
        case 'franchise':
            return '加盟店';
        case 'virtual':    
            return '虚拟店';
        case 'flagship':
            return '旗舰店';   
        case 'family':
            return '社区店';
        default:
            return '无状态'
    }
}

export default Helper