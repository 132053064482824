import React from 'react';
import { Spin } from 'antd';
import Loadable from 'react-loadable';

const Loading = () => <div className="lodings"><Spin className="spin" tip="Loading..." /></div>;
// 首页
const Index = Loadable({
    loader: () => import('../page/index/index'),
    loading: Loading,
})
// 登陆
const Login = Loadable({
    loader: () => import('../page/login/index'),
    loading: Loading,
})
// Home
const Admin = Loadable({
    loader: () => import('../page/admin/index'),
    loading: Loading,
})

// 首页
const Home = Loadable({
    loader: () => import('../page/view/home'),
    loading: Loading,
})

// 首页会员数据
// const MemberData = Loadable({
//     loader: () => import('../page/view/memberData'),
//     loading: Loading,
// })

// 菜单
const Menu = Loadable({
    loader: () => import('../page/view/menu'),
    loading: Loading,
})
// 角色
const Role = Loadable({
    loader: () => import('../page/view/role'),
    loading: Loading,
})
// 管理员
const Administrators = Loadable({
    loader: () => import('../page/view/administrators'),
    loading: Loading,
})
// 代理商查询管理
const Agent = Loadable({
    loader: () => import('../page/view/agent'),
    loading: Loading,
})
// 代理商审核管理
const Agentread = Loadable({
    loader: () => import('../page/view/agentread'),
    loading: Loading,
})
// 代理商拒绝
const Agentrefuse = Loadable({
    loader: () => import('../page/view/agentrefuse'),
    loading: Loading,
})
// 门店查询
const Store = Loadable({
    loader: () => import('../page/view/store'),
    loading: Loading,
})
// 门店审核
const Storeread = Loadable({
    loader: () => import('../page/view/storeread'),
    loading: Loading,
})
// 门店审核-第二次
const Storereadagan = Loadable({
    loader: () => import('../page/view/storereadagan'),
    loading: Loading,
})
// 门店审核-第二次
const Storelog = Loadable({
    loader: () => import('../page/view/storelog'),
    loading: Loading,
})
// 门店审核-第二次
const Storefictitious = Loadable({
    loader: () => import('../page/view/storefictitious'),
    loading: Loading,
})
// 会员管理
const Member = Loadable({
    loader: () => import('../page/view/member'),
    loading: Loading,
})
// 会员管理-资金账户
const MemberCapitalAccount = Loadable({
    loader: () => import('../page/view/memberCapitalAccount'),
    loading: Loading,
})
// 会员管理-会员分类
const MemberClassification = Loadable({
    loader: () => import('../page/view/memberClassification'),
    loading: Loading,
})
// 供货商管理
const Supplier = Loadable({
    loader: () => import('../page/view/supplier'),
    loading: Loading,
})
// 供货商管理-采购单
const SupplierPurchase = Loadable({
    loader: () => import('../page/view/supplierPurchase'),
    loading: Loading,
})
// 供货商管理-价格设置
const SupplierSetting = Loadable({
    loader: () => import('../page/view/supplierSetting'),
    loading: Loading,
})
// 供货商管理-运费设置
const SupplierFreight = Loadable({
    loader: () => import('../page/view/supplierFreight'),
    loading: Loading,
})
// 商品管理
const Commodity = Loadable({
    loader: () => import('../page/view/commodity'),
    loading: Loading,
})
// 商品管理审核
const Commodityread = Loadable({
    loader: () => import('../page/view/commodityread'),
    loading: Loading,
})
// 商品管理-待审核价格
const PriceApproval = Loadable({
    loader: () => import('../page/view/priceApproval'),
    loading: Loading,
})
// 商品管理-价格列表
const PriceList = Loadable({
    loader: () => import('../page/view/priceList'),
    loading: Loading,
})
// 商品管理-商品分类
const CommodityFe = Loadable({
    loader: () => import('../page/view/commodityFe'),
    loading: Loading,
})
// 商品管理-商品分类
const CommodityRule = Loadable({
    loader: () => import('../page/view/commodityRule'),
    loading: Loading,
})
// 商品管理-商品套餐

const CommoditySetmeal = Loadable({
    loader: () => import('../page/view/commoditySetmeal'),
    loading: Loading,
})

// 商品管理-代理商商品
const CommodityAgent = Loadable({
    loader: () => import('../page/view/commodityAgent'),
    loading: Loading,
})

// 订货查询
const OrderGoods = Loadable({
    loader: () => import('../page/view/orderGoods'),
    loading: Loading,
})
// 待确认订货单
const OrderGoodstaytrue = Loadable({
    loader: () => import('../page/view/orderGoodstaytrue'),
    loading: Loading,
})
// 待付款订货单
const OrderGoodstaypay = Loadable({
    loader: () => import('../page/view/orderGoodstaypay'),
    loading: Loading,
})
// 待付款订货单-二次确认
const OrderGoodstaypayAgain = Loadable({
    loader: () => import('../page/view/orderGoodstaypayAgain'),
    loading: Loading,
})

// 分仓订货单
const OrderGoodbranch = Loadable({
    loader: () => import('../page/view/orderGoodbranch'),
    loading: Loading,
})
// 发货订货单
const OrderGoodsend = Loadable({
    loader: () => import('../page/view/orderGoodsend'),
    loading: Loading,
})
// 采购单列表
const PurchaseOrder = Loadable({
    loader: () => import('../page/view/purchaseOrder'),
    loading: Loading,
})
// 订货详情
const OrderGoodsDetail = Loadable({
    loader: () => import('../page/view/orderGoodsDetail'),
    loading: Loading,
})
// 订货入库
const OrderGoodsWarehousing = Loadable({
    loader: () => import('../page/view/orderGoodsWarehousing'),
    loading: Loading,
})
// 订货出库
const OrderGoodsOutStock = Loadable({
    loader: () => import('../page/view/orderGoodsOutStock'),
    loading: Loading,
})
// 返桶-已收货
const Returnbucketget = Loadable({
    loader: () => import('../page/view/returnbucketget'),
    loading: Loading,
})
// 返桶-已收货
const ReturnbucketWarehousing = Loadable({
    loader: () => import('../page/view/returnbucketWarehousing'),
    loading: Loading,
})
// 返桶-已收货
const Returnbucketpayment = Loadable({
    loader: () => import('../page/view/returnbucketpayment'),
    loading: Loading,
})
// 代理商管理-酒桶管理
const ReturnbucketAgent = Loadable({
    loader: () => import('../page/view/returnbucketAgent'),
    loading: Loading,
})
// 返桶-已发货
const Returnbucketsend = Loadable({
    loader: () => import('../page/view/returnbucketsend'),
    loading: Loading,
})
// 返桶-待支付
const Returnbucketstaypay = Loadable({
    loader: () => import('../page/view/returnbucketstaypay'),
    loading: Loading,
})
// 返桶-待确认
const Returnbucketstaytrue = Loadable({
    loader: () => import('../page/view/returnbucketstaytrue'),
    loading: Loading,
})
// 订单管理-待结算
const OrderManageStay = Loadable({
    loader: () => import('../page/view/orderManageStay'),
    loading: Loading,
})
// 订单管理-已结算
const OrderManageEnd = Loadable({
    loader: () => import('../page/view/orderManageEnd'),
    loading: Loading,
})
// 订单管理-退款列表
const OrderManageRefund = Loadable({
    loader: () => import('../page/view/orderManageRefund'),
    loading: Loading,
})
// 订单管理-销售列表
const OrderManageList = Loadable({
    loader: () => import('../page/view/orderManageList'),
    loading: Loading,
})
// 商学院
const BusinessSchool = Loadable({
    loader: () => import('../page/view/businessSchool'),
    loading: Loading,
})
// 退货管理-退货申请
const ReturnRequest = Loadable({
    loader: () => import('../page/view/returnRequest'),
    loading: Loading,
})
// 退货管理-退货确认
const ReturnConfirmation = Loadable({
    loader: () => import('../page/view/returnConfirmation'),
    loading: Loading,
})
// 退货管理-退货查询
const ReturnInquiry = Loadable({
    loader: () => import('../page/view/returnInquiry'),
    loading: Loading,
})
// 退货管理-财务审核
const ReturnConfirmationagain = Loadable({
    loader: () => import('../page/view/returnConfirmationagain'),
    loading: Loading,
})
// 退货管理-财务付款
const ReturnConfirmationpay = Loadable({
    loader: () => import('../page/view/returnConfirmationpay'),
    loading: Loading,
})

// 提货卡管理-提货卡
const Deliverycard = Loadable({
    loader: () => import('../page/view/deliverycard'),
    loading: Loading,
})
// 提货卡管理-审核
const DeliveryExamine = Loadable({
    loader: () => import('../page/view/deliveryExamine'),
    loading: Loading,
})
// 提货卡管理-代理商
const Deliverysend = Loadable({
    loader: () => import('../page/view/deliverysend'),
    loading: Loading,
})
// 提货卡管理-使用记录
const Deliveryuser = Loadable({
    loader: () => import('../page/view/deliveryuser'),
    loading: Loading,
})

// 优惠券-查询
const CouponQuery = Loadable({
    loader: () => import('../page/view/couponQuery'),
    loading: Loading,
})
// 优惠券-规则相关查询
const CouponRuleCorrelation = Loadable({
    loader: () => import('../page/view/couponRuleCorrelation'),
    loading: Loading,
})
// 优惠券-规则查询
const CouponRule = Loadable({
    loader: () => import('../page/view/couponRule'),
    loading: Loading,
})
// 优惠券-场景查询
const CouponScene = Loadable({
    loader: () => import('../page/view/couponScene'),
    loading: Loading,
})
// 优惠券-活动查询
const CouponActivity = Loadable({
    loader: () => import('../page/view/couponActivity'),
    loading: Loading,
})
// 售后单管理-售后单查询
const AfterSaleList = Loadable({
    loader: () => import('../page/view/afterSaleList'),
    loading: Loading,
})
// 售后单管理-设置运费
const AfterSaleSetFreight = Loadable({
    loader: () => import('../page/view/afterSaleSetFreight'),
    loading: Loading,
})
// 售后管理-主管审核
const AfterSaleSetexecutive = Loadable({
    loader: () => import('../page/view/afterSaleSetexecutive'),
    loading: Loading,
})
// 售后管理-待付款
const AfterSaleSetexecutivepay = Loadable({
    loader: () => import('../page/view/afterSaleSetexecutivepay'),
    loading: Loading,
})
// 售后管理-待发货
const AfterSaleSetdelivered = Loadable({
    loader: () => import('../page/view/afterSaleSetdelivered'),
    loading: Loading,
})

// 售后单管理-财务审核
const AfterSaleFinancialAudit = Loadable({
    loader: () => import('../page/view/afterSaleFinancialAudit'),
    loading: Loading,
})
// 售后单管理-厂家鉴定
const AfterSaleFactoryAppraisal = Loadable({
    loader: () => import('../page/view/afterSaleFactoryAppraisal'),
    loading: Loading,
})

// 财务管理-提现审核
const FinancialWithdrawal = Loadable({
    loader: () => import('../page/view/financialWithdrawal'),
    loading: Loading,
})
// 财务管理-付款审核
const FinancialPayment = Loadable({
    loader: () => import('../page/view/financialPayment'),
    loading: Loading,
})
// 财务管理-付款成功
const FinancialPaymentEnd = Loadable({
    loader: () => import('../page/view/financialPaymentEnd'),
    loading: Loading,
})

// 系统设置-基础设置
const SystemSettingsBasics = Loadable({
    loader: () => import('../page/view/systemSettingsBasics'),
    loading: Loading,
})
// 系统设置-分销设置
const SystemSettingsSistribution = Loadable({
    loader: () => import('../page/view/systemSettingsSistribution'),
    loading: Loading,
})

const mainRouter = [
    { path: "/", name: "首页", component: Index, exact: true},
    { path: "/login", name: "登录", component: Login},
    { path: "/admin", name: "首页", component: Admin}
]

const siderRouter = [
    { path: "/admin", name: "后台默认界面", component: Home, exact: true},
    // { path: "/admin/memberData", name: "会员数据", component: MemberData, exact: true},
    { path: "/admin/menu", name: "菜单管理", component: Menu, exact: true},
    { path: "/admin/role", name: "角色管理", component: Role, exact: true},
    { path: "/admin/administrators", name: "管理员", component: Administrators, exact: true},
    { path: "/admin/agent", name: "代理商查询", component: Agent, exact: true},
    { path: "/admin/agentread", name: "代理商审核", component: Agentread, exact: true},
    { path: "/admin/agentrefuse", name: "代理商拒绝", component: Agentrefuse, exact: true},
    { path: "/admin/store", name: "门店查询", component: Store, exact: true},
    { path: "/admin/storeread", name: "门店审核", component: Storeread, exact: true},
    { path: "/admin/storereadagan", name: "门店二次审核", component: Storereadagan, exact: true},
    { path: "/admin/storelog", name: "门店审核日志", component: Storelog, exact: true},
    { path: "/admin/storefictitious", name: "虚拟店查询", component: Storefictitious, exact: true},
    { path: "/admin/member", name: "会员管理", component: Member, exact: true},
    { path: "/admin/memberCapitalAccount", name: "资金管理", component: MemberCapitalAccount, exact: true},
    { path: "/admin/memberClassification", name: "会员分类", component: MemberClassification, exact: true},
    { path: "/admin/supplier", name: "供货商管理", component: Supplier, exact: true},
    { path: "/admin/supplierPurchase", name: "供货商采购单", component: SupplierPurchase, exact: true},
    { path: "/admin/supplierSetting", name: "价格设置", component: SupplierSetting, exact: true},
    { path: "/admin/supplierFreight", name: "运费设置", component: SupplierFreight, exact: true},
    { path: "/admin/businessSchool", name: "商学院", component: BusinessSchool, exact: true},
    { path: "/admin/commodity", name: "商品管理", component: Commodity, exact: true},
    { path: "/admin/commodityread", name: "商品管理审核", component: Commodityread, exact: true},
    { path: "/admin/priceApproval", name: "待审核价格", component: PriceApproval, exact: true},
    { path: "/admin/commodityFe", name: "商品分类", component: CommodityFe, exact: true},
    { path: "/admin/commodityRule", name: "商品规格", component: CommodityRule, exact: true},
    { path: "/admin/commoditySetmeal", name: "商品套餐", component: CommoditySetmeal, exact: true},
    { path: "/admin/commodityAgent", name: "代理商商品", component: CommodityAgent, exact: true},
    { path: "/admin/priceList", name: "价格列表", component: PriceList, exact: true},
    { path: "/admin/orderGoods", name: "订货查询", component: OrderGoods, exact: true},
    { path: "/admin/orderGoodsDetail", name: "订单详情", component: OrderGoodsDetail, exact: true},
    { path: "/admin/orderGoodsWarehousing", name: "订单入库", component: OrderGoodsWarehousing, exact: true},
    { path: "/admin/orderGoodsOutStock", name: "订单出库", component: OrderGoodsOutStock, exact: true},
    { path: "/admin/returnbucketstaytrue", name: "待确认申请", component: Returnbucketstaytrue, exact: true},
    { path: "/admin/returnbucketstaypay", name: "财务确认", component: Returnbucketstaypay, exact: true},
    { path: "/admin/returnbucketsend", name: "返桶取件", component: Returnbucketsend, exact: true},
    { path: "/admin/returnbucketget", name: "返桶查询", component: Returnbucketget, exact: true},
    { path: "/admin/returnbucketpayment", name: "返桶待付款", component: Returnbucketpayment, exact: true},
    { path: "/admin/returnbucketWarehousing", name: "返桶入库", component: ReturnbucketWarehousing, exact: true},
    { path: "/admin/returnbucketAgent", name: "酒桶管理", component: ReturnbucketAgent, exact: true},
    { path: "/admin/orderGoodstaytrue", name: "待确认订货单", component: OrderGoodstaytrue, exact: true},
    { path: "/admin/orderGoodstaypay", name: "待付款订货单", component: OrderGoodstaypay, exact: true},
    { path: "/admin/orderGoodstaypayAgain", name: "待付款二次确认", component: OrderGoodstaypayAgain, exact: true},
    { path: "/admin/orderGoodbranch", name: "分仓订货单", component: OrderGoodbranch, exact: true},
    { path: "/admin/orderGoodsend", name: "发货订货单", component: OrderGoodsend, exact: true},
    { path: "/admin/purchaseOrder", name: "采购单列表", component: PurchaseOrder, exact: true},
    { path: "/admin/orderManageStay", name: "订单管理待结算", component: OrderManageStay, exact: true},
    { path: "/admin/orderManageEnd", name: "订单管理已结算", component: OrderManageEnd, exact: true},
    { path: "/admin/orderManageRefund", name: "订单管理退款列表", component: OrderManageRefund, exact: true},
    { path: "/admin/orderManageList", name: "订单管理销售订单", component: OrderManageList, exact: true},
    { path: "/admin/returnRequest", name: "退货申请", component: ReturnRequest, exact: true},
    { path: "/admin/returnConfirmation", name: "主管审核", component: ReturnConfirmation, exact: true},
    { path: "/admin/returnConfirmationagain", name: "财务审核", component: ReturnConfirmationagain, exact: true},
    { path: "/admin/returnConfirmationpay", name: "财务付款", component: ReturnConfirmationpay, exact: true},
    { path: "/admin/returnInquiry", name: "退货查询", component: ReturnInquiry, exact: true},
    { path: "/admin/deliverycard", name: "提货卡查询", component: Deliverycard, exact: true},
    { path: "/admin/deliveryExamine", name: "提货卡审核", component: DeliveryExamine, exact: true},
    { path: "/admin/deliverysend", name: "代理商管理", component: Deliverysend, exact: true},
    { path: "/admin/deliveryuser", name: "使用记录", component: Deliveryuser, exact: true},
    { path: "/admin/couponQuery", name: "优惠券查询", component: CouponQuery, exact: true},
    { path: "/admin/couponRuleCorrelation", name: "规则查询", component: CouponRuleCorrelation, exact: true},
    { path: "/admin/couponRule", name: "优惠券规则查询", component: CouponRule, exact: true},
    { path: "/admin/couponScene", name: "场景查询", component: CouponScene, exact: true},
    { path: "/admin/couponActivity", name: "活动查询", component: CouponActivity, exact: true},
    { path: "/admin/afterSaleList", name: "售后单查询", component: AfterSaleList, exact: true},
    { path: "/admin/afterSaleSetFreight", name: "设置运费", component: AfterSaleSetFreight, exact: true},
    { path: "/admin/afterSaleSetexecutive", name: "主管审核", component: AfterSaleSetexecutive, exact: true},
    { path: "/admin/afterSaleSetexecutivepay", name: "主管审核", component: AfterSaleSetexecutivepay, exact: true},
    { path: "/admin/afterSaleSetdelivered", name: "待发货", component: AfterSaleSetdelivered, exact: true},
    { path: "/admin/afterSaleFinancialAudit", name: "财务审核", component: AfterSaleFinancialAudit, exact: true},
    { path: "/admin/afterSaleFactoryAppraisal", name: "厂家鉴定", component: AfterSaleFactoryAppraisal, exact: true},
    { path: "/admin/financialWithdrawal", name: "提现审核", component: FinancialWithdrawal, exact: true},
    { path: "/admin/financialPayment", name: "付款审核", component: FinancialPayment, exact: true},
    { path: "/admin/financialPaymentEnd", name: "付款成功", component: FinancialPaymentEnd, exact: true},
    { path: "/admin/systemSettingsBasics", name: "基础设置", component: SystemSettingsBasics, exact: true},
    { path: "/admin/systemSettingsSistribution", name: "分销设置", component: SystemSettingsSistribution, exact: true},
]

export { mainRouter, siderRouter }
